import { defineStore } from "pinia"

import type { Destination } from "@/types/types"

export const useDestinationStore = defineStore({
  id: "destination-store",
  // https://pinia.vuejs.org/core-concepts/state.html#typescript
  state: () => {
    return {
      destinations: [] as Destination[],
    }
  },
  actions: {
    addDestination(destination: Destination) {
      this.destinations.push(destination)
    },
    updateDestination(id: string, destination: Destination) {
      const index = this.destinations.findIndex((d) => d.id === id)
      if (index !== -1) this.destinations[index] = destination
    },
    updateDestinations(destinations: Destination[]) {
      this.destinations = destinations
    },
  },
  getters: {},
})
