import { useAuth } from "@/composables/auth"

function authorizationHeaders() {
  // for now default to first org in JWT, add custom header for org
  // selection later
  const { user, accessToken } = useAuth()
  if (user) return { authorization: "Bearer " + accessToken() }

  // in development mode allow setting a static org token
  const config = useRuntimeConfig()
  const devMode = config.public.deploymentEnvironment === "development"
  if (devMode && config.public.apiToken) {
    return { authorization: "Bearer " + config.public.apiToken }
  }

  return { authorization: "" }
}

// wrap $fetch with configuration needed to talk to our API
// use this when making requests based on user actions
//
// https://nuxt.com/docs/getting-started/data-fetching#fetch

export const $api = <T>(
  request: Parameters<typeof $fetch<T>>[0],
  options?: Parameters<typeof $fetch<T>>[1]
) => {
  const config = useRuntimeConfig()
  options ||= {}
  options.baseURL = config.public.apiBaseUrl
  options.headers = {
    ...authorizationHeaders(),
    ...options.headers,
  }

  return $fetch<T>(request, options)
}

// wrap useFetch with configuration needed to talk to our API
// use this when loading data, will auto-reload if params are reactive
// and they change. don't use this for mutation requests to the API.
//
// https://nuxt.com/docs/api/composables/use-fetch

type useFetchType = typeof useFetch

export const useAPIFetch: useFetchType = (path, options = {}) => {
  const config = useRuntimeConfig()

  options.baseURL = config.public.apiBaseUrl
  options.headers = {
    ...authorizationHeaders(),
    ...options.headers,
  }

  return useFetch(path, options)
}

// Create a websocket connection to the server at the given path
// Protocol and base URL will be inferred from the API base URL
export function useWebSocket(path: string): WebSocket {
  const config = useRuntimeConfig()
  const [protocol, baseUrl] = config.public.apiBaseUrl.split("://")
  const wsProtocol = protocol === "http" ? "ws" : "wss" // default to secure
  const url = wsProtocol + "://" + (baseUrl + path).replace("//", "/")
  // it would be nice to provide open & close methods that could be
  // deconstructed out of the composable. If this gets more complex
  // (heartbeating, etc) consider vue-use implementation
  return new WebSocket(url)
}
